import { Outlet } from 'react-router-dom';
import DesktopNavbar from '../components/Navbar/DesktopNavbar';
import NavHeader from '../components/NavHeader';
import MobileNavbar from '../components/Navbar/MobileNavbar';

function MainLayout() {
  return (
    <>
      {window.innerWidth >= 768 ? <DesktopNavbar /> : <MobileNavbar />}
      <NavHeader />
      <Outlet />
    </>
  )
}

export default MainLayout