import { useState } from "react";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import { handleResetPropertyError } from "../../hooks/ErrorHandling";

function RemoveProperties({ token, selectedOrg, activeRemoveProperties, setActiveRemoveProperties }) {
    const [statement, setStatement] = useState('');
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');

    const handleSubmit = () => {
        if (statement === "Remove all properties") {
            fetch(`${process.env.REACT_APP_API_URL}/organizations/${selectedOrg}/reallocate`, {
                method: "DELETE",
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            })
                .then((response) => {
                    if (!response.ok) {
                        const errorMessage = handleResetPropertyError(response.status);
                        toast.error(errorMessage);
                    } else {
                        toast.success('Properties successfully removed.')
                        setActiveRemoveProperties(false);
                        setStatement('');
                    }
                })
                .catch((error) => {
                    toast.error(error)
                })
        } else {
            setError(true);
            setHelperText('Incorrect input.');
        }
    };

    const handleChange = (e) => {
        setStatement(e.target.value);
        setError(false);
        setHelperText('');
    }

    return (
        <div className={`relative z-10 ${activeRemoveProperties ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="mt-3 sm:mt-0 text-left">
                            <h1 className="text-lg font-semibold mb-2">Are you sure you want to remove all of the properties for this organization?</h1>
                            <p className="text-base leading-6 text-gray-900" id="modal-title">To confirm, please type in “Remove all properties” below:</p>
                            <div className="mt-2">
                                <TextField
                                    sx={{ 'width': '100%' }}
                                    size="small"
                                    value={statement}
                                    name='first_name'
                                    onChange={handleChange}
                                    error={error}
                                    helperText={helperText}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button" onClick={handleSubmit} className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 sm:ml-3 sm:w-auto">Remove Properties</button>
                        <button type="button" onClick={() => setActiveRemoveProperties(false)} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RemoveProperties