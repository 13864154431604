import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { handleAuthError } from "./ErrorHandling";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem("user") || "");
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();
  const loginAction = async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(data),
      });
      
      if (!response.ok) {
        const errorMessage = handleAuthError(response.status);
        toast.error(errorMessage);
      } else {
        const res = await response.json();
        if (res.access_token) {
          setToken(res.access_token);
          localStorage.setItem("site", res.access_token);
          const responseMe = await fetch(`${process.env.REACT_APP_API_URL}/me`, {
            headers: { "Authorization": `Bearer ${res.access_token}` }
          });
          const resMe = await responseMe.json();
          if (resMe) {
            setUser(resMe)
            localStorage.setItem("user", JSON.stringify(resMe))
          }
          navigate("/home");
          return;
        }
      }
    } catch (error) {
      toast.error('Network error! Please check your internet connection.');
    }
  };

  const checkTokenExpiration = () => {
    const decode = JSON.parse(atob(token.split('.')[1]));
    if (decode.exp * 1000 < new Date().getTime()) {
      logOut();
    }
  };

  const logOut = () => {
    setToken("");
    setUser("")
    localStorage.removeItem("site");
    localStorage.removeItem("user");
    navigate("/");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, checkTokenExpiration, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};