import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useAuth } from "../hooks/AuthProvider";
import { handleAddOrgError } from "../hooks/ErrorHandling";
import RemoveProperties from "../components/Modals/RemoveProperties";

function Organizations() {
  const [active, setActive] = useState(false);
  const [listOfOrganizations, setListOfOrganizations] = useState([]);
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [activeRemoveProperties, setActiveRemoveProperties] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [dataUpdate, setDataUpdate] = useState(false);
  const token = localStorage.getItem("site") || "";
  const user = JSON.parse(localStorage.getItem("user")) || "";

  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/organizations`, {
      headers: { "Authorization": `Bearer ${token}` }
    })
      .then(response => response.json())
      .then(data => setListOfOrganizations(data))
  }, [dataUpdate])

  useEffect(() => {
    const intervalId = setInterval(auth.checkTokenExpiration, 500);
    return () => clearInterval(intervalId);
  }, [])

  const handleSelectOrganization = (orgId) => {
    console.log(orgId);
    setActiveRemoveProperties(true);
    setSelectedOrg(orgId);
  }

  const handleSubmit = async (e) => {
    if (name.length === 0 || url.length === 0) {
      toast.error("You must fill out the following forms completely.")
    } else {
      const organizationData = {
        name: name,
        url: url
      };
      
      fetch(`${process.env.REACT_APP_API_URL}/organizations`, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
        body: JSON.stringify(organizationData),
      })
        .then((response) => {
          if (!response.ok) {
            const errorMessage = handleAddOrgError(response.status);
            toast.error(errorMessage);
          } else {
            toast.success('Organization successfully added.')
            setActive(false)
            setDataUpdate(!dataUpdate)
            navigate('/home')
            setName('')
            setUrl('')
          }
        })
        .catch((error) => alert("error creating organization"))
    }

  };

  return (
    <>
      <div className="px-5 py-24 md:px-10 lg:w-4/5 lg:mx-auto">
        <div className="w-full flex justify-end">
          <button onClick={() => setActive(true)} className="flex bg-dark-900 items-center justify-center gap-1 rounded-md px-3 py-2 w-24 hover:bg-cyan-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="stroke-neutral-50 w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            <p className="font-semibold text-neutral-50 text-md">ADD</p>
          </button>
        </div>
        <table className="rounded-t-md border-collapse overflow-hidden mt-4 table-fixed shadow w-full">
          <thead className="bg-dark-900 text-white">
            <tr>
              <th className="text-left p-4">Name</th>
              <th className="text-left p-4"></th>
              {user.email_address === 'oyster_admin@oysterdata.com' &&
              <th className="text-left p-4"></th>
              }
            </tr>
          </thead>
          <tbody>
            {listOfOrganizations.map((organization) => {
              return (
                <>
                  <tr className="odd:bg-white even:bg-slate-100">
                    <td className="p-4">{organization.name}</td>
                    <td className="p-4 text-center">
                      <Link to={`/organizations/${organization.id}`}>
                        <div className="bg-dark-600 text-white p-3 rounded-md hover:bg-blue-600">
                          View Properties
                        </div>
                      </Link>
                    </td>
                    {user.email_address === 'oyster_admin@oysterdata.com' &&
                    <td className="p-4 text-center">
                      <div onClick={() => handleSelectOrganization(organization.id)} className="bg-red-600 text-white p-3 rounded-md hover:bg-red-400 hover:cursor-pointer">
                        Remove Properties
                      </div>
                    </td>
                    }
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className={`relative z-10 ${active ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="mt-3 sm:mt-0 text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Fill out the following fields to add a new organization:</h3>
                  <div className="mt-2">
                    <label htmlFor="organization name" className="block text-sm font-medium leading-6 text-gray-900">Name</label>
                    <input onChange={(e) => {setName(e.target.value)}} value={name} type="text" name="org_name" id="org_name" className="flex p-2 w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600" placeholder="Enter organization name"></input>
                  </div>
                  <div className="mt-2">
                    <label htmlFor="organization URL" className="block text-sm font-medium leading-6 text-gray-900">URL</label>
                    <input onChange={(e) => {setUrl(e.target.value)}} value={url} type="text" name="org_url" id="org_url" className="flex p-2 w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600" placeholder="Enter organization URL"></input>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button type="button" onClick={handleSubmit} className="inline-flex w-full justify-center rounded-md bg-dark-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-400 sm:ml-3 sm:w-auto">Add Organization</button>
                <button type="button" onClick={() => setActive(false)} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RemoveProperties 
        token={token}
        selectedOrg={selectedOrg}
        activeRemoveProperties={activeRemoveProperties}
        setActiveRemoveProperties={setActiveRemoveProperties}
      />
    </>
  );
}

export default Organizations;
