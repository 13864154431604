import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';

import full_logo from '../../static/images/logo_horizontal_gradwhite.png';
import logo from '../../static/images/logo_gradwhite_oyster.png';

function DesktopNavbar() {
    
    const [isOpen, setIsOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('Organizations');

    const navbarItems = [
        {
            name: 'Organizations', 
            icon:   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`group-hover:stroke-white w-7 h-7 ${activeTab === 'Organizations' ? 'stroke-white' : 'stroke-gray-7'}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                    </svg>, 
            link: '/home'
        },
        {
            name: 'Users', 
            icon:   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`group-hover:stroke-white w-7 h-7 ${activeTab === 'Users' ? 'stroke-white' : 'stroke-gray-7'}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                    </svg>, 
            link: '/users'
        },
        {
            name: 'Appliance Catalog', 
            icon:   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`group-hover:stroke-white w-7 h-7 ${activeTab === 'Appliance Catalog' ? 'stroke-white' : 'stroke-gray-7'}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z" />
                    </svg>, 
            link: '/appliance-catalog'
        },
    ]

    return (
        <div className={`sticky top-0 transition-all duration-500 ease-in-out z-10 h-screen ${isOpen ? 'md:w-52 lg:w-64' : 'md:w-20'}`}>
            <nav className="h-full overflow-hidden">
                <div className="bg-gray-11 border-b-[1px] border-black p-5 h-20">
                    {isOpen ? (
                        <img className="h-full" src={full_logo} alt="Cocoon Homes logo" />
                        ) : (
                        <img className="w-10" src={logo} alt="Cocoon Homes logo" />
                    )}
                </div>
                <div className="bg-gradient-to-b from-gray-10 to-gray-9 h-full first-child:pb-0 py-4">
                    {navbarItems.map((item) => {
                        return (
                            <div onClick={() => setActiveTab(item.name)} className="mb-3 px-4 first:pb-0 last:pt-0">
                                <Link to={item.link}>
                                    {isOpen ? (
                                        <div key={item.name} className={`group flex gap-4 px-4 py-3 justify-left items-center hover:bg-gray-9 hover:rounded-lg ${activeTab === item.name ? 'bg-gray-9 rounded-lg' : ''}`}>
                                            {item.icon}
                                            <div className={`group-hover:text-white ${activeTab === item.name ? 'text-white' : 'text-gray-7'}`}>{item.name}</div>
                                        </div>
                                    ) : (
                                        <div key={item.name} className={`group flex p-2 items-center justify-center hover:bg-gray-9 hover:rounded-lg ${activeTab === item.name ? 'bg-gray-9 rounded-lg' : ''}`}>
                                            {item.icon}
                                        </div>
                                    )}
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </nav>
            {!isOpen ? (             
                    <div onClick={() => setIsOpen((isOpen) => !isOpen)} className="absolute top-6 right-[-17px] h-8 w-8 rounded-full bg-white shadow flex justify-center items-center hover:cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="stroke-secondary-blue-2 hover:stroke-gray-9 w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                        </svg>
                    </div>
                ) : (
                    <div onClick={() => setIsOpen((isOpen) => !isOpen)} className="absolute top-6 right-[-17px] h-8 w-8 rounded-full bg-white shadow flex justify-center items-center hover:cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="stroke-secondary-blue-2 hover:stroke-gray-9 w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                        </svg>
                    </div>
                )
            }
        </div>
    )
}

export default DesktopNavbar