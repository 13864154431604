import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useAuth } from "../hooks/AuthProvider";
import { handleAddUserError, handleGetError } from "../hooks/ErrorHandling";

import UserRecords from "../components/Users/UserRecords";
import AddNewUserModal from "../components/Modals/AddNewUserModal";
import Pagination from "../components/Pagination";

function Users() {
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const [dataUpdate, setDataUpdate] = useState(false);
  const [active, setActive] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState({});
  const [organizationId, setOrganizationId] = useState('');
  const [usersData, setUsersData] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const [totalRecords, setTotalRecords] = useState('');

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const firstRecord = indexOfFirstRecord + 1
  const lastRecord = (totalRecords < indexOfLastRecord) ? totalRecords : indexOfLastRecord
  
  const currentRecords = usersData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(usersData.length / recordsPerPage)

  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/users`, {
      headers: { "Authorization": `Bearer ${token}` }
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 401) {
            navigate('/');
          } else {
            const errorMessage = handleGetError(response.status);
            toast.error(errorMessage);
          }
        } else {
          return response.json();
        }
      })
      .then(data => {
        setUsersData(data);
        setTotalRecords(data.length);
      })
  }, [dataUpdate])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/organizations`, {
      headers: { "Authorization": `Bearer ${token}` }
    })
      .then(response => response.json())
      .then(data => {
        setOrganizationData(data);
      })
  }, [])

  useEffect(() => {
    const intervalId = setInterval(auth.checkTokenExpiration, 500);
    return () => clearInterval(intervalId);
  }, [])

  const selectOrganization = (org) => {
    const object = JSON.parse(org);
    setOrganizationId(object.id);
    setOrganization(object);
  }

  const handleSubmit = async (e) => {
    const userData = {
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      password: '',
      organization: organization,
      role: 'ADMIN',
      organization_id: organizationId
    };

    fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        if (!response.ok) {
          const errorMessage = handleAddUserError(response.status);
          toast.error(errorMessage);
        } else {
          toast.success('New user successfully created.')
          setActive(false);
          setDataUpdate(!dataUpdate);
          setFirstName('');
          setLastName('');
          setEmail('');
          setOrganization({});
          setOrganizationId('');
          navigate('/users');
        }
      })
      .catch((error) => toast.error("Internal Server Error"))
  };

  return (
    <>
      <div className="px-5 py-24 md:px-10 small:flex small:flex-col small:grow lg:w-4/5">
        <div className="w-full flex justify-end">
          <button onClick={() => setActive(true)} className="light-blue-button">
            <p>Add User</p>
          </button>
        </div>
        <UserRecords data={currentRecords} />
        <Pagination 
          page="Users"
          nPages={nPages}
          firstRecord={firstRecord}
          lastRecord={lastRecord}
          currentPage={currentPage}
          totalRecords={totalRecords}
          setRecordsPerPage={setRecordsPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <AddNewUserModal 
        active={active} 
        setActive={setActive} 
        firstName={firstName} 
        setFirstName={setFirstName} 
        lastName={lastName} 
        setLastName={setLastName} 
        email={email} 
        setEmail={setEmail} 
        selectOrganization={selectOrganization}
        handleSubmit={handleSubmit}
        organizationData={organizationData}
      />
    </>
  );
}

export default Users;
