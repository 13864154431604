import { BrowserRouter as Router, Route, Routes } from "react-router-dom";import './App.css';
import AuthProvider from "./hooks/AuthProvider";
import MainLayout from './layout/MainLayout';
import PrivateRoute from './router/route';

import Login from './views/Login';
import Organizations from './views/Organizations';
import Users from './views/Users';
import Properties from './views/Properties';
import ApplianceCatalog from "./views/ApplianceCatalog";
import AccountSettings from "./views/AccountSettings";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route element={<MainLayout />} >
              <Route path='/home' element={<Organizations />} />
              <Route path='/organizations/:id' element={<Properties />} />
              <Route path='/users' element={<Users />} />
              <Route path='/appliance-catalog' element={<ApplianceCatalog />} />
              <Route path='/account-settings' element={<AccountSettings />} />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
