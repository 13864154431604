import { useState } from "react";
import { toast } from 'react-toastify';
import { handleAddCatalogError } from "../../hooks/ErrorHandling";
import { TextField } from "@mui/material";

function AddNewApplianceModal({ isActive, setIsActive }) {
    const [token, setToken] = useState(localStorage.getItem("site") || "");
    const [appliance, setAppliance] = useState({
        category: '',
        make: '',
        model: '',
        product_url: '',
        highlights: '',
        images: {
            url: ''
        },
        manuals: {
            title: '',
            url: ''
        },
    });

    const handleChange = (e) => {
        setAppliance({...appliance, [e.target.name]: e.target.value});
    }

    const handleSubmit = () => {
        toast.info('testing');
        // fetch(`${process.env.REACT_APP_API_URL}/properties/${selectedProperty}/inspection_report`, {
        //     method: "POST",
        //     headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
        //     body: inspectionReport,
        // })
        //     .then(response => {
        //         if (!response.ok) {
        //             const errorMessage = handleAddCatalogError(response.status);
        //             toast.error(errorMessage);
        //         } else {
        //             setDataUpdate(!dataUpdate);
        //             setActiveInspectionModal(!activeInspectionModal);
        //             setInspectionReport('');
        //             toast.success('Inspection report uploaded successfully');
        //         }
        //     })
        //     .catch(error => toast.error('Internal Server Error'))
    }

    return (
        <div className={`relative z-10 ${isActive ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[80%]">
                    <svg onClick={() => setIsActive(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute top-4 right-4 stroke-secondary-blue-3 w-6 h-6 hover:cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-10 sm:pb-8">
                        <div className="mt-3 mb-12 sm:mt-0 text-left">
                            <h3 className="title-3" id="modal-title">Add New Appliance Reference</h3>
                            <div className="flex gap-5 w-full">
                                <div className="w-1/3">
                                    <h6 className="title-4 leading-9 mt-3">Category</h6>
                                    <TextField
                                        sx={{ 'width': '100%' }}
                                        placeholder="Category"
                                        size="small"
                                        name="category"
                                        value={appliance.category}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="w-1/3">
                                    <h6 className="title-4 leading-9 mt-3">Make</h6>
                                    <TextField
                                        sx={{ 'width': '100%' }}
                                        placeholder="Make"
                                        size="small"
                                        name="make"
                                        value={appliance.make}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="w-1/3">
                                    <h6 className="title-4 leading-9 mt-3">Model</h6>
                                    <TextField
                                        sx={{ 'width': '100%' }}
                                        placeholder="Model"
                                        size="small"
                                        name="model"
                                        value={appliance.model}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="w-full">
                                <h6 className="title-4 leading-9 mt-3">Product URL</h6>
                                <TextField
                                    sx={{ 'width': '100%' }}
                                    placeholder="Product URL"
                                    size="small"
                                    name="product_url"
                                    value={appliance.product_url}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-full">
                                <h6 className="title-4 leading-9 mt-3">Product Highlights</h6>
                                <TextField
                                    sx={{ 'width': '100%' }}
                                    rows={5}
                                    placeholder="Product Highlights"
                                    size="small"
                                    name="highlights"
                                    value={appliance.highlights}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-full">
                                <h6 className="title-4 leading-9 mt-3">Manuals</h6>
                                <div className="flex gap-5">
                                    <div className="w-2/5">
                                        <h6 className="title-7">Manual Title</h6>
                                        <TextField
                                            sx={{ 'width': '100%' }}
                                            rows={5}
                                            placeholder="Title"
                                            size="small"
                                            name="title"
                                            value={appliance.manuals.title}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="w-3/5">
                                        <h6 className="title-7">Manual URL</h6>
                                        <div className="flex gap-5">
                                            <TextField
                                                sx={{ 'width': '74%' }}
                                                rows={5}
                                                placeholder="URL"
                                                size="small"
                                                name="url"
                                                value={appliance.manuals.url}
                                                onChange={handleChange}
                                            />
                                            <button className="gray-button">Add Manual</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <h6 className="title-4 leading-9 mt-3">Images</h6>
                                <div>
                                    <h6 className="title-7">Image URL</h6>
                                    <div className="flex gap-5">
                                        <TextField
                                            sx={{ 'width': '86%' }}
                                            rows={5}
                                            placeholder="Image URL"
                                            size="small"
                                            name="url"
                                            value={appliance.images.url}
                                            onChange={handleChange}
                                        />
                                        <button className="gray-button">Add Image</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sm:flex sm:flex-row-reverse sm:gap-4">
                            <button type="button" onClick={handleSubmit} className="light-blue-button">Add Appliance</button>
                            <button type="button" onClick={() => setIsActive(false)} className="white-button">Cancel</button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewApplianceModal