import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { handleAddCatalogError } from "../../hooks/ErrorHandling";

function AddNewPropertyImageModal({ selectedProperty, dataUpdate, setDataUpdate, activePhotoModal, setActivePhotoModal }) {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || "");
    const [token, setToken] = useState(localStorage.getItem("site") || "");
    const [file, setFile] = useState(null);
    const [propertyImage, setPropertyImage] = useState(null);
    const [view, setView] = useState('photo');

    const fileTypes = ["jpg", "jpeg", "png", 'gif'];

    const handleChange = (file) => {
        setFile(file);
        setPropertyImage(URL.createObjectURL(file));
        setView('update');
    }

    const handleImageUpdate = async () => {
        const attachments = await handleImageUpload();
        const file_name = attachments.file_name;
        const cloud_key = attachments.file_key;

        const dataBody = JSON.stringify({
            "file_name": file_name,
            "cloud_key": cloud_key
        })

        fetch(`${process.env.REACT_APP_API_URL}/properties/${selectedProperty}/add-image`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            body: dataBody
        })
            .then((response) => {
                if (!response.ok) {
                    const errorMessage = handleAddCatalogError(response.status);
                    toast.error(errorMessage);
                } else {
                    toast.success("Property image successfully uploaded.");
                    clearFields();
            }})
            .catch((error) => {
                toast.error(error);
            })
    }

    const handleImageUpload = async () => {
        const fileName = file.name.split(" ").join("");

        const response = await fetch(`${process.env.REACT_APP_API_URL}/gcp/${user.organization.id}/signed-url?file_name=${fileName}`)
        if (!response.ok) {
            toast.error("Signed URL could not be obtained.");
            return;
        }
        const data = await response.json()

        const uploadResponse = await fetch(data.signed_url, {
            method: "PUT",
            headers: { "Content-Type": "application/octet-stream" },
            body: file
        });
        
        if (!uploadResponse.ok) {
            toast.error(`File upload of ${file.name} unsuccessful.`);
            return;
        }
        
        return { file_name: file.name, file_key: data.blob_name }
    }

    const clearFields = () => {
        setActivePhotoModal(false);
        setPropertyImage(null);
        setView('photo');
    }

    const stack2 = (
        <div className="bg-gray-2 border-2 border-dotted border-gray-6 text-center rounded-lg p-4">
            <div className="flex justify-center gap-3 w-full mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="stroke-secondary-blue-3 w-10 h-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="stroke-secondary-blue-3 w-10 h-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                </svg>
            </div>
            <h3 className="title-6 mb-1">Drag & Drop or Choose File to Upload a Photo</h3>
            <p className="caption-text text-secondary-blue-3 mb-3">JPG, PNG, and GIF formats, up to 5 MB.</p>
            <button className="light-blue-button-small">Browse</button>
        </div>
    );

    return (
        <div className={`relative z-50 ${activePhotoModal ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[40rem] sm:min-h-68">
                        <div className="bg-white relative px-4 pb-4 pt-5 sm:p-10">
                            <svg onClick={clearFields} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute top-4 right-4 stroke-secondary-blue-3 w-6 h-6 hover:cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                            <div>
                                <h1 className="title-5 mb-10">Upload Property Image</h1>
                                {view === 'photo' &&
                                    <div>
                                        <FileUploader 
                                            handleChange={handleChange} 
                                            required={true}
                                            label="Upload or drop files here"
                                            types={fileTypes} 
                                            children={stack2}
                                        />
                                        <div className="w-full flex justify-end items-end mt-10 gap-3">
                                            <button onClick={clearFields} className="white-button">Cancel</button>
                                        </div>
                                    </div>
                                }
                                {view === 'update' &&
                                    <div>
                                        <div className="w-full h-44 flex justify-center">
                                            <img className="max-h-44" src={propertyImage} />
                                        </div>
                                        <div className="w-full flex justify-end items-end mt-10 gap-3">
                                            <button onClick={clearFields} className="white-button">Cancel</button>
                                            <button onClick={handleImageUpdate} className="light-blue-button">Upload</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewPropertyImageModal