import { useState } from 'react';

function AccordionAppliance({ appliance }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleAccordion = () => {
      setIsExpanded(!isExpanded);
    };
  
    return (
        <>
            <tr onClick={toggleAccordion} className={`odd:bg-gray-3 even:bg-gray-1 hover:cursor-pointer hover:bg-gray-4 ${isExpanded ? 'bg-gray-4' : ''}`}>
                <td className="px-4 py-2 text-left">{appliance}</td>
                <td className="px-4 py-2 text-left">{appliance}</td>
                <td className="px-4 py-2 flex justify-end items-center">
                    {isExpanded ? 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 stroke-secondary-blue-3">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    :
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 stroke-secondary-blue-3">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    }
                </td>
            </tr>
            {isExpanded &&
            <tr>
                <td colSpan="2" className="px-10 pt-2 pb-4 text-left">
                    <div className="flex justify-between items-center border-b-[1px] border-b-gray-5 pb-2">
                        <h6 className="title-6">Manage Catalogs</h6>
                        <div className="flex justify-center gap-1">
                            <button className="light-blue-button hover:bg-cyan-400">Add Catalog</button>
                        </div>
                    </div>
                </td>
            </tr>
            }
        </>
    );
}

export default AccordionAppliance