import { useState } from 'react';

function AccountSettings() {
    const [toggle, setToggle] = useState(false);
    const [password, setPassword] = useState('');

    return (
        <div className="pt-24 px-5">
            <h1 className="title-1 mb-5">Account Settings</h1>
            <div>
                <h2 className="title-5 mb-2">Change Password</h2>
                <div className="relative">
                    <div onClick={() => setToggle(!toggle)} className="bg-gray-300 w-[40px] h-[40px] absolute bottom-0 right-0 overflow-hidden flex justify-center items-center rounded-r-md hover:cursor-pointer">
                        {toggle ? (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-white">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-white">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                            </svg>
                        )}
                    </div>
                    <input onChange={(e) => setPassword(e.target.value)} type={`${toggle ? 'text' : 'password'}`} name="password" id="password" className="flex mb-2 p-2 w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none"></input>
                </div>
            </div>
        </div>
    )
}

export default AccountSettings