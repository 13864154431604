function AddNewUserModal({ active, setActive, firstName, setFirstName, lastName, setLastName, email, setEmail, selectOrganization, handleSubmit, organizationData }) {

    return (
        <div className={`relative z-10 ${active ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="mt-3 sm:mt-0 text-left">
                        <h1 className="text-lg font-semibold mb-2">By filling out this form, you are creating an Administrator for a customer organization.</h1>
                        <p className="text-base leading-6 text-gray-900" id="modal-title">Fill out the following fields to add a new administrator:</p>
                        <div className="mt-2">
                            <label htmlFor="first name" className="block text-sm font-medium leading-6 text-gray-900">First Name</label>
                            <input onChange={(e) => setFirstName(e.target.value)} value={firstName} type="text" name="first_name" id="first_name" className="flex p-2 w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600" placeholder="Enter first name"></input>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="last name" className="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
                            <input onChange={(e) => setLastName(e.target.value)} value={lastName} type="text" name="last_name" id="last_name" className="flex p-2 w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600" placeholder="Enter last name"></input>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email Address</label>
                            <input onChange={(e) => setEmail(e.target.value)} value={email} type="text" name="email" id="email" className="flex p-2 w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600" placeholder="Enter email address"></input>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="organization">Organization</label>
                            <select onChange={(e) => selectOrganization(e.target.value)} className="text-black rounded p-1 mx-2 h-3/4" name="number">
                            <option>Select an Organization</option>
                                {organizationData.map((org) => {
                                    return (
                                        <option value={JSON.stringify(org)}>{org.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button" onClick={handleSubmit} className="inline-flex w-full justify-center rounded-md bg-dark-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-400 sm:ml-3 sm:w-auto">Add User</button>
                        <button type="button" onClick={() => setActive(false)} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewUserModal