import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../hooks/AuthProvider';

import PropertiesTable from '../components/Properties/PropertiesTable';
import AddNewProperty from '../components/Properties/AddNewProperty';

function Properties() {
    const { id } = useParams();
    const auth = useAuth();

    const [totalRecords, setTotalRecords] = useState('');
    const [dataUpdate, setDataUpdate] = useState(false);
    const [organizationName, setOrganizationName] = useState('');
    const [propertiesData, setPropertiesData] = useState([]);
    const [token, setToken] = useState(localStorage.getItem("site") || "");
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/organizations/${id}`, {
            headers: { "Authorization": `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => setOrganizationName(data.name))
    }, [id])
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/organizations/${id}/properties`, {
            headers: { "Authorization": `Bearer ${token}` }
        })
            .then(response => {
                if (!response.ok) {
                    console.log('wtf')
                    toast.error(response.status)
                } else {
                    return response.json()
                }
            })
            .then(data => {
                setPropertiesData(data)
                setTotalRecords(data.length)
            })
            .catch(error => toast.error('Internal Server Error'))
    }, [id, dataUpdate])

    useEffect(() => {
        const intervalId = setInterval(auth.checkTokenExpiration, 500);
        return () => clearInterval(intervalId);
    }, [])

    return (
        <div className="w-full pt-24 flex place-content-center py-10">
            {propertiesData && propertiesData.length > 0
                ? <PropertiesTable 
                    token={token}
                    propertiesData={propertiesData} 
                    name={organizationName}
                    organizationId={id}
                    totalRecords={totalRecords}
                    dataUpdate={dataUpdate}
                    setDataUpdate={setDataUpdate} 
                /> 
                : <AddNewProperty 
                    organizationId={id}
                    dataUpdate={dataUpdate}
                    setDataUpdate={setDataUpdate} 
                />
            }
        </div>
    )
}

export default Properties