import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const [user, setUser] = useState(localStorage.getItem("user") || "");
  if (!user) return <Navigate to="/" />;
  return <Outlet />;
};

export default PrivateRoute;
