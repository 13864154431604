import { toast} from 'react-toastify';
import { handleAddCatalogError, handleResetPropertyError } from "../../hooks/ErrorHandling";

import AccordionItem from '../Accordion/AccordionItem';

function PropertyRecords({ token, data, dataUpdate, setDataUpdate, activeCatalogModal, setActiveCatalogModal, activeInspectionModal, setActiveInspectionModal, activePhotoModal, setActivePhotoModal, setSelectedProperty }) {
    const handleAddCatalogClick = (property) => {
        setActiveCatalogModal(!activeCatalogModal)
        setSelectedProperty(property)
    }

    const handleAddInspectionClick = (property) => {
        setActiveInspectionModal(!activeInspectionModal)
        setSelectedProperty(property)
    }

    const handleAddPhotoClick = (property) => {
        setActivePhotoModal(!activePhotoModal)
        setSelectedProperty(property)
    }

    const handleDeleteCatalog = (catalog) => {
        fetch(`${process.env.REACT_APP_API_URL}/catalogs/${catalog}`, {
                method: "DELETE",
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }
            })
            .then((response) => {
                if (!response.ok) {
                    const errorMessage = handleAddCatalogError(response.status);
                    toast.error(errorMessage);
                } else {
                    toast.success('Catalog has been successfully deleted.')
                    setDataUpdate(!dataUpdate)
                }
            })
            .catch((error) => toast.error("Internal Server Error"))
    }

    const handleResetProperty = (property) => {
        fetch(`${process.env.REACT_APP_API_URL}/properties/${property}/reset`, {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }
        })
        .then((response) => {
            if (!response.ok) {
                const errorMessage = handleResetPropertyError(response.status);
                toast.error(errorMessage);
            } else {
                toast.success('Property has been successfully reset.')
            }
        })
        .catch((error) => toast.error("Internal Server Error"))
    }

    return (
        <table className="table-fixed shadow w-full mt-2">
            <thead className="bg-gray-9">
                <tr className="text-white">
                    <th className="px-2 py-2 w-10 text-left"></th>
                    <th className="px-2 py-2 text-left">Address</th>
                </tr>
            </thead>
            <tbody>
                {data.map((data) => {
                    return (
                        <AccordionItem 
                            data={data}
                            handleAddCatalogClick={handleAddCatalogClick}
                            handleDeleteCatalog={handleDeleteCatalog}
                            handleAddInspectionClick={handleAddInspectionClick}
                            handleAddPhotoClick={handleAddPhotoClick}
                            handleResetProperty={handleResetProperty}
                        />
                    )
                })}
            </tbody>
        </table>
    )
}

export default PropertyRecords