import { useState } from "react";
import AccordionAppliance from "../components/Accordion/AccordionAppliance";
import AddNewApplianceModal from "../components/Modals/AddNewApplianceModal";

function ApplianceCatalog() {
    const [isActive, setIsActive] = useState(false);
    const appliances = ['test data'];

    return (
        <>
            <div className="px-5 py-24 md:px-10 small:flex small:flex-col small:grow lg:w-4/5">
                <div className="w-full flex justify-end">
                    <button onClick={() => setIsActive(true)} className="light-blue-button">
                        <p>Add Appliance</p>
                    </button>
                </div>
                <table className="rounded-md border-collapse overflow-hidden mt-4 table-fixed shadow w-full">
                    <thead className="bg-dark-900 text-white">
                        <tr>
                            <th className="text-left p-4 w-6/12">Name</th>
                            <th className="text-left p-4 w-5/12">Status</th>
                            <th className="text-left p-4 w-1/12"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {appliances.map((appliance, index) => {
                            return (
                                <AccordionAppliance key={index} appliance={appliance} />
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <AddNewApplianceModal
                isActive={isActive}
                setIsActive={setIsActive}
            />
        </>
    )
}

export default ApplianceCatalog