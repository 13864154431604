import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AvatarOptions from './AvatarOptions';
import personas from '../static/images/Personas.png';

function NavHeader() {
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem("user")) || "";
    const [toggleAvatar, setToggleAvatar] = useState(false);

    const { first_name, last_name } = user;

    const firstInitial = first_name.split('')[0];
    const lastInitial = last_name.split('')[0];

    useEffect(() => {
        setToggleAvatar(false)
    }, [location])

    return (
        <div className="fixed w-full top-0 z-0 h-20 pr-6 gap-5 bg-white shadow flex justify-end items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="stroke-secondary-blue-3 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
            <div onClick={() => setToggleAvatar(!toggleAvatar)} className="flex gap-2 hover:cursor-pointer">
                <div className="h-9 w-9 bg-slate-500 rounded-full flex items-center justify-center">
                    <div className="title-5 leading-none font-medium text-gray-1">{firstInitial}{lastInitial}</div>
                </div>
                <div className="flex flex-col justify-center">
                    <h6 className="caption-text font-semibold">{user.first_name} {user.last_name}</h6>
                    <p className="legal-text text-gray-8">System Admin</p>
                </div>
            </div>
            <AvatarOptions toggleAvatar={toggleAvatar} />
        </div>
    )
}

export default NavHeader