import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { handleAddCatalogError } from "../../hooks/ErrorHandling";

function AddNewCatalogModal({ dataUpdate, setDataUpdate, activeCatalogModal, selectedProperty, setActiveCatalogModal }) {
    const [catalog, setCatalog] = useState('');
    const [token, setToken] = useState(localStorage.getItem("site") || "");

    const navigate = useNavigate();

    const handleSubmit = () => {
        if (catalog.length === 0) {
            toast.error(`Please enter valid data in JSON format.`);
        } else {
            fetch(`${process.env.REACT_APP_API_URL}/catalogs/import/${selectedProperty}`, {
                method: "POST",
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
                body: catalog,
            })
                .then((response) => {
                    if (!response.ok) {
                        const errorMessage = handleAddCatalogError(response.status);
                        toast.error(errorMessage);
                    } else {
                        toast.success("New catalog successfully added.")
                        setActiveCatalogModal(false)
                        setCatalog('')
                        setDataUpdate(!dataUpdate)
                    }
                })
                .catch((error) => {
                    if (error.message === '401') {
                            toast.error('Unauthorized! Please log in.');
                        } else if (error.message === '500') {
                            toast.error('Invalid JSON format.');
                        } else {
                            toast.error('Something went wrong! Please try again later.');
                        }
                })
        }
    }

    return (
        <div className={`relative z-10 ${activeCatalogModal ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="mt-3 sm:mt-0 text-left">
                            <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Fill out the following fields to add a new catalog:</h3>
                            <div className="mt-2">
                                <label htmlFor="catalog" className="block text-sm font-medium leading-6 text-gray-900">Catalog Data</label>
                                <textarea onChange={(e) => setCatalog(e.target.value)} value={catalog} type="text" rows="10" name="catalog" id="catalog" className="flex p-2 w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600" placeholder="Enter catalog data"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button" onClick={handleSubmit} className="inline-flex w-full justify-center rounded-md bg-dark-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-400 sm:ml-3 sm:w-auto">Add Catalog</button>
                        <button type="button" onClick={() => setActiveCatalogModal(false)} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewCatalogModal