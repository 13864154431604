import { useState } from "react";
import { toast } from 'react-toastify';
import { handleAddCatalogError } from "../../hooks/ErrorHandling";

function AddNewPropertyReportModal({ organizationId, dataUpdate, setDataUpdate, activePropertyReportModal, setActivePropertyReportModal }) {
    const [token, setToken] = useState(localStorage.getItem("site") || "");
    const [inspectionReport, setInspectionReport] = useState('');

    const handleSubmit = () => {
        fetch(`${process.env.REACT_APP_API_URL}/organizations/${organizationId}/inspection_report`, {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            body: inspectionReport,
        })
            .then(response => {
                if (!response.ok) {
                    const errorMessage = handleAddCatalogError(response.status);
                    toast.error(errorMessage);
                } else {
                    setDataUpdate(!dataUpdate);
                    setActivePropertyReportModal(!activePropertyReportModal);
                    setInspectionReport('');
                    toast.success('Inspection report uploaded successfully');
                }
            })
            .catch(error => toast.error('Internal Server Error'))
    }

    return (
        <div className={`relative z-10 ${activePropertyReportModal ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="mt-3 sm:mt-0 text-left">
                            <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Fill out the following fields to add a new inspection report:</h3>
                            <div className="mt-2">
                                <label htmlFor="Inspection Report" className="block text-sm font-medium leading-6 text-gray-900">Inspection Report Data</label>
                                <textarea onChange={(e) => setInspectionReport(e.target.value)} value={inspectionReport} type="text" rows="10" name="Inspection Report" id="Inspection Report" className="flex p-2 w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600" placeholder="Enter inspection report data"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button" onClick={handleSubmit} className="inline-flex w-full justify-center rounded-md bg-dark-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-400 sm:ml-3 sm:w-auto">Add Inspection Report</button>
                        <button type="button" onClick={() => setActivePropertyReportModal(false)} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewPropertyReportModal