function Pagination({ page, nPages, firstRecord, lastRecord, currentPage, setRecordsPerPage, setCurrentPage, totalRecords }) {
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    const recordsAmount = [15, 30, 50]

    const goToNextPage = () => {
        if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const goToPrevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }

    return (
        <nav className="bg-gray-9 w-full rounded-b-md text-white">
            <div className="flex px-4 py-1 justify-between">
                <div className="flex items-center gap-1 h-12">
                    <p className="text-left">Showing</p>
                    <select onChange={(e) => setRecordsPerPage(e.target.value)} className="text-black rounded p-1 mx-2 h-3/4" name="number">
                        {recordsAmount.map((amount) => {
                            return (
                                <option value={amount}>{amount}</option>
                            )
                        })}
                    </select>
                    <p className="text-left">{page} {firstRecord} to {lastRecord} of {totalRecords}</p>
                </div>
                <ul className="flex items-center space-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 hover:cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                    </svg>
                    <svg onClick={goToPrevPage} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 hover:cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>
                    {pageNumbers.map(pgNumber => (
                        <li key={pgNumber} 
                            className= {`px-3 py-1 hover:cursor-pointer ${currentPage === pgNumber ? 'bg-white text-dark-900 rounded-full' : ''} `} >
                            <p onClick={() => setCurrentPage(pgNumber)}>
                                {pgNumber}
                            </p>
                        </li>
                    ))}
                    <svg onClick={goToNextPage} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 hover:cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 hover:cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                    </svg>
                </ul>
            </div>
        </nav>
    )
}

export default Pagination