import { useState } from 'react';

function AccordionItem({ data, handleAddCatalogClick, handleDeleteCatalog, handleAddInspectionClick, handleAddPhotoClick, handleResetProperty }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleAccordion = () => {
      setIsExpanded(!isExpanded);
    };
  
    return (
        <>
            <tr onClick={toggleAccordion} className={`odd:bg-gray-3 even:bg-gray-1 hover:cursor-pointer hover:bg-gray-4 ${isExpanded ? 'bg-gray-4' : ''}`}>
                <td colSpan="2" className="px-2 py-2 flex justify-between items-center">
                    {isExpanded ? 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-8 stroke-secondary-blue-3">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    :
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-8 stroke-secondary-blue-3">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    }
                </td>
                <td className="px-2 py-2 text-left">{data.formatted_address}</td>
            </tr>
            {isExpanded &&
            <tr>
                <td colSpan="2" className="px-10 pt-2 pb-4 text-left">
                    <div className="flex justify-between items-center border-b-[1px] border-b-gray-5 pb-2">
                        <h6 className="title-6">Manage Catalogs</h6>
                        <div className="flex justify-center gap-1">
                            {data.catalog ?
                                <>
                                    <button onClick={() => handleAddCatalogClick(data.id)} className="light-blue-button hover:bg-cyan-400">Add Catalog</button>
                                    <button onClick={() => handleDeleteCatalog(data.catalog.id)} className="light-blue-button hover:bg-cyan-400">Delete Catalog</button>
                                </>
                                    :
                                    <button onClick={() => handleAddCatalogClick(data.id)} className="light-blue-button hover:bg-cyan-400">Add Catalog</button>
                                }
                        </div>
                    </div>
                    <div className="pt-2">
                        <div className="flex justify-between items-center border-b-[1px] border-b-gray-5 pb-2">
                            <h6 className="title-6">Manage Inspection Reports</h6>
                            <div className="flex justify-center gap-1">
                                <button onClick={() => handleAddInspectionClick(data.id)} className="light-blue-button hover:bg-cyan-400">Add Report</button>
                            </div>
                        </div>
                    </div>
                    <div className="pt-2">
                        <div className="flex justify-between items-center border-b-[1px] border-b-gray-5 pb-2">
                            <h6 className="title-6">Upload Property Image</h6>
                            <div className="flex justify-center gap-1">
                                <button onClick={() => handleAddPhotoClick(data.id)} className="light-blue-button hover:bg-cyan-400">Add Property Image</button>
                            </div>
                        </div>
                    </div>
                    <div className="pt-2">
                        <div className="flex justify-between items-center">
                            <h6 className="title-6">Reset Property</h6>
                            <div className="flex justify-center gap-1">
                                <button onClick={() => handleResetProperty(data.id)} className="light-blue-button hover:bg-cyan-400">Reset Property</button>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            }
        </>
    );
}

export default AccordionItem